import { useGetCharts, useGetStats } from 'api/useStatsApi';
import { useAuth } from 'auth/AuthProvider';
import { Page } from 'layouts/Admin/Page';
import { Link } from 'react-router-dom';
import { StatGrid } from './components/StatGrid';
import { ChartGrid } from './components/ChartGrid';

export const DashboardView = () => {
  const { data: stats } = useGetStats();
  const { data: charts } = useGetCharts();
  const { user } = useAuth();

  if (!stats || !charts) return null;

  return (
    <Page title={user?.organization.name || 'Oversigt'}>
      <WelcomeText />
      <StatGrid stats={stats} />
      <ChartGrid charts={charts} />
    </Page>
  );
};

const WelcomeText = () => {
  return (
    <div className="mb-4">
      <h1 className="font-bold">Velkommen til Smart Create</h1>
      <div className="text-gray-600">
        <p>
          Hvis du har spørgsmål, feedback eller skulle opleve udfordringer, er
          du velkommen til at oprette en{' '}
          <Link className="text-primary" to="/organization/service-tickets">
            supportsag
          </Link>
          , så hjælper vi dig videre.
        </p>
      </div>
    </div>
  );
};
